import {useTranslation} from "react-i18next"; 
import ScrollToTop from "../ScrollToTop";
const TermsCondition  = ()=>{
const {t} = useTranslation();
  
    return (
        <>
            <ScrollToTop/>
            <div name='termscondition' className='container px-4 mx-auto py-10' style={{ marginTop:"100px" }} data-aos="fade-up-right" data-aos-duration="1500" data-aos-delay="1100">
                {/* Contact title Start */}
                <div className="text-4xl font-semibold text-primary flex justify-center mb-10">
                <div>
                    <h2 className="">{t("terms_conditions")}</h2>
                    <div className="border border-t-2 border-t-primary w-16 mt-3"></div>
                </div>
            </div>
            {/* Contact title end  */}
                 <div >
                    <h3 style={{ fontWeight:"bold",fontSize:"20px" }}>{t('last_updated')}</h3>
                    <p>20-08-2022</p>
                </div>

                <div className='mt-5'> 
                    <p>{t('h_content')}</p> 
                </div> 


                 <div className='mt-5' >
                    <h3 style={{ fontWeight:"bold",fontSize:"20px" }}>{t('definitions')}</h3>
                </div>

                <div className='mt-5'> 
                    <p>{t('definitions_content')}</p> 
                </div> 

                 <div className='mt-5' >
                    <h3 style={{ fontWeight:"bold",fontSize:"20px" }}>{t('access')}</h3>
                </div>

                <div className='mt-5'> 
                    <p>{t('acce_content')}</p> 
                </div> 

                 <div className='mt-5' >
                    <h3 style={{ fontWeight:"bold",fontSize:"20px" }}>{t('disclaimer')}</h3>
                </div>

                <div className='mt-5'> 
                    <p>{t('disclaimer_content')}</p> 
                </div> 


                 <div className='mt-5' >
                    <h3 style={{ fontWeight:"bold",fontSize:"20px" }}>{t('our_obligation_to_the_customer')}</h3>
                </div>

                <div className='mt-5'> 
                    <p>{t('our_obligation_to_the_customer_content')}</p> 
                </div> 


                 <div className='mt-5' >
                    <h3 style={{ fontWeight:"bold",fontSize:"20px" }}>{t('delays_in_service')}</h3>
                </div>

                <div className='mt-5'> 
                    <p>{t('delays_in_service_content')}</p> 
                </div> 

                 <div className='mt-5' >
                    <h3 style={{ fontWeight:"bold",fontSize:"20px" }}>{t('collections_and_deliveries')}</h3>
                </div>

                <div className='mt-5'> 
                    <p>{t('collections_and_deliveries_content')}</p> 
                </div> 


                 <div className='mt-5' >
                    <h3 style={{ fontWeight:"bold",fontSize:"20px" }}>{t('prohibited_restricted_items')}</h3>
                </div>

                <div className='mt-5'> 
                    <p>{t('prohibited_restricted_items_content')}</p> 
                </div> 


                 <div className='mt-5' >
                    <h3 style={{ fontWeight:"bold",fontSize:"20px" }}>{t('types_of_shipments')}</h3>
                </div>

                <div className='mt-5'> 
                    <p>{t('types_of_shipments_content')}</p> 
                </div> 

        </div>
    </>
    );
}

export default TermsCondition;