import { Envelope, Facebook, GeoAlt, Linkedin, Telephone, Twitter, Youtube } from 'react-bootstrap-icons';
import playStoreImage from './../../assets/images/google-play-badge.svg';
 
import iStoreImage from './../../assets/images/app-store.png';
import systemLogo from './../../assets/images/rx-logo.jpeg';
import Subscribe from  './../Shared/Subscribe' 
import {useTranslation} from "react-i18next"; 
import { Link } from 'react-router-dom' 

export default function Footer() {
   const { t } = useTranslation();
   const datetime = new Date();
  return (
    <footer className="text-gray-600 body-font mt-20">
        <div className='bg-gray-100'>
            <div className="container py-10 mx-auto   ">
                <div className="flex flex-wrap md:text-left text-center -mb-10  pb-10 mb-10 ">
                    {/* Column 1 */}
                    <div className="lg:w-1/4 md:w-1/2 w-full pr-4 pb-8 md:pb-0">
                        <h1 className="mr-3 text-3xl font-bold text-orange-500 mb-3"><img src="/logo.png"  style={{ height:"80px" }} alt="logo" /></h1>
                        <p>{t("download_the_rx_courier_app_for_the_better_experience")}</p> 
                        <div className="flex gap-10 mt-10 ">
                            <a href="https://play.google.com/store/apps/details?id=rxcourier.com.bd" download><img  src={playStoreImage} alt="google-play"/></a>
                            <a href="#"><img src={iStoreImage}    alt="i-store"/></a>
                        </div>   
                    </div>

                    {/* Column 2  */}
                    <div className="lg:w-1/4 md:w-1/2 w-full px-4 flex flex-col items-center">
                        
                        <nav className="list-none mb-10">
                            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">{t("important_links")}</h2>
                            <li>
                                <Link to='/coverage' className="text-gray-600 hover:text-gray-800">{t("our_coverage")}</Link>
                            </li>
 
                            <li>
                        
                                <a className="text-gray-600 hover:text-gray-800" href={`${process.env.REACT_APP_BASE_URL}/login`} target="_blank">
                                    {t('became_a_merchant')}
                                </a>
                             
                            </li>
                            <li>
                                <Link to='/privacy-policy' className="text-gray-600 hover:text-gray-800">{t("privacy_policy")}</Link>
                            </li>
                            <li>
                                <Link to='/terms-condition' className="text-gray-600 hover:text-gray-800">{t('terms_conditions')}</Link>
                            </li>
                        </nav>
                    </div>

                    {/* Column 3  */}
                    <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                        <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">{t('address')}</h2>
                        <nav className="list-none mb-10">
                            <li className='flex items-center justify-center md:justify-start'>
                                <div className='bg-orange-50 shadow-lg p-2 rounded-full mr-2 my-1'>
                                    <GeoAlt className='text-2xl text-orange-500'></GeoAlt>
                                </div>
                                <p className="text-gray-600 hover:text-gray-800">{t('location')}: 4th chan mention, foll potti goli. Mirpur 10. Dhaka - 1216</p>
                            </li>
                            <li className='flex items-center justify-center md:justify-start'>
                                <div className='bg-orange-50 shadow-lg p-2 rounded-full mr-2 my-1'>
                                    <Envelope className='text-2xl text-orange-500'></Envelope>
                                </div>
                                <p className="text-gray-600 hover:text-gray-800">{t('email')}: info@rxcourier.com.bd</p>
                            </li>
                            <li className='flex items-center justify-center md:justify-start'>
                                <div className='bg-orange-50 shadow-lg p-2 rounded-full mr-2 my-1'>
                                    <Telephone className='text-2xl text-orange-500'></Telephone>
                                </div>
                                <p className="text-gray-600 hover:text-gray-800">{t('phone')}: +88 1974-434044</p>
                            </li>
                        </nav>
                    </div>

                    {/* Column 4  */}
                    <div className="lg:w-1/4 md:w-1/2 w-full pl-4 mb-5">
                        <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">{t("follow_us")}</h2>
                        <span className="inline-flex lg:ml-auto lg:mt-0 mt-6 w-full justify-center md:justify-start md:w-auto">
                            <a href='https://www.facebook.com/rxcourierbd' target="_blank" className="text-gray-500 bg-orange-50 shadow-lg p-3 rounded-full">
                                <Facebook className='text-2xl text-orange-500'></Facebook>
                            </a>
                            <a href='https://twitter.com/rxcourierbd' target="_blank" className="ml-3 text-gray-500 bg-orange-50 shadow-lg p-3 rounded-full">
                                <Twitter className='text-2xl text-orange-500'></Twitter>
                            </a>
                            <a href='https://www.linkedin.com/in/rx-courier-299509246' target="_blank" className="ml-3 text-gray-500 bg-orange-50 shadow-lg p-3 rounded-full">
                                <Linkedin className='text-2xl text-orange-500'></Linkedin>
                            </a>
                            <a href='https://www.youtube.com/channel/UCC0SVFWuNGq3u7shBnuaBjA' target="_blank" className="ml-3 text-gray-500 bg-orange-50 shadow-lg p-3 rounded-full">
                                <Youtube className='text-2xl text-orange-500'></Youtube>
                            </a>
                        </span>
                        <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm my-3">{t('subscribe')}</h2>
                         
                            <Subscribe />
                       
                    </div>
                    
                </div>
            </div>
        </div>

        <div className="bg-gray-100 border border-t-2">
            <div className="container mx-auto py-4 flex flex-wrap flex-col sm:flex-row">
            <p className="text-gray-500 text-sm text-center sm:text-left">© {datetime.getFullYear()} {t('rxcourier')} — Development by <a href="https://wemaxit.com/">Rx-Courier</a>
            </p>
                <span className="sm:ml-auto sm:mt-0 mt-2 sm:w-auto w-full sm:text-left text-center text-gray-500 text-sm">{t("make_delivery_faster")}</span>
            </div>
        </div>
    </footer>
  )
}
