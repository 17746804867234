import React ,{useState} from 'react';
import { Envelope, GeoAlt, Telephone } from 'react-bootstrap-icons';
import contactUsImage from './../../assets/images/contact-us.png';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import axios from 'axios';
import {useTranslation} from "react-i18next"; 
import 'react-toastify/dist/ReactToastify.css';
const Contact  = ()=>{
    const { t } = useTranslation();
    const { register, formState: { errors }, handleSubmit ,reset} = useForm();

    
    const onSubmit = (data) =>{ 
    
            const loading = toast.loading('Please waiting...', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme:'dark', 
            });
     
      
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/v10/contact-us`,data)
        .then((res)=>{  
           
            toast.update(loading, { render: res.data.message, type: "success",  autoClose: 5000, isLoading: false });
            reset({name:'',email: '',subject:'',message:''});
        })
        .catch((error)=>{
           
            toast.update(loading, { render: 'Something went wrong.', type: "error",  autoClose: 5000, isLoading: false });
            console.log(error);
        });
        
    };

    return (
        <div name='contact-us' className='container px-4 mx-auto py-10' data-aos="fade-up-right" data-aos-duration="1500" data-aos-delay="1100">
            {/* Contact title Start */}
            <div className="text-4xl font-semibold text-primary flex justify-center mb-10">
            <div>
                <h2 className="">{t("contact_us")}</h2>
                <div className="border border-t-2 border-t-primary w-16 mt-3"></div>
            </div>
        </div>
        {/* Contact title end  */}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">

            <div>
                <div>
                    <img  src={contactUsImage}  alt="Contact Us" />
                </div>
                <div>
                    <ul className='grid grid-cols-1 md:grid-cols-3 gap-5'>
                        <li className='flex items-center justify-center md:justify-start'>
                            <div className='bg-orange-50 shadow-lg p-2 rounded-full mr-2 my-1'>
                                <GeoAlt className='text-2xl text-orange-500'></GeoAlt>
                            </div>
                            <p className="text-gray-600 hover:text-gray-800">Location: # 4th chan mention, foll potti goli. Mirpur 10. Dhaka - 1216</p>
                        </li>
                        <li className='flex items-center justify-center md:justify-start'>
                            <div className='bg-orange-50 shadow-lg p-2 rounded-full mr-2 my-1'>
                                <Envelope className='text-2xl text-orange-500'></Envelope>
                            </div>
                            <p className="text-gray-600 hover:text-gray-800">Email: info@rxcourier.com.bd</p>
                        </li>
                        <li className='flex items-center justify-center md:justify-start'>
                            <div className='bg-orange-50 shadow-lg p-2 rounded-full mr-2 my-1'>
                                <Telephone className='text-2xl text-orange-500'></Telephone>
                            </div>
                            <p className="text-gray-600 hover:text-gray-800">Phone: +88 1974-434044</p>
                        </li>
                    </ul>
                </div>
            </div>

            
            <div className="card flex-shrink-0 w-full shadow-2xl bg-base-100">
                <div className="card-body">
                    <h3 className='text-3xl font-semibold text-center'>{t("send_us_a_message")}</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">{t('your_name')}</span>
                            </label>
                            <input type="text" {...register("name", { required: true })} placeholder={t('name')} className="input input-primary" />
                            <p className='text-red-500'>{errors.name?.type === 'required' && "Name field is required"}</p>
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">{t('email')}</span>
                            </label>
                            <input type="email" {...register("email", { required: true })} placeholder={t('email')} className="input input-primary" />
                            <p className='text-red-500'>{errors.email?.type === 'required' && "Email field is required"}</p>
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">{t('subject')}</span>
                            </label>
                            <input type="text" {...register("subject", { required: true })} placeholder={t('subject')} className="input input-primary" />
                            <p className='text-red-500'>{errors.subject?.type === 'required' && "Subject field is required"}</p>
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">{t('message')}</span>
                            </label>
                            <textarea {...register("message", { required: true })} className="textarea textarea-primary" placeholder={t("message")}></textarea>
                            <p className='text-red-500'>{errors.message?.type === 'required' && "Message field is required"}</p>
                        </div>
                        <div className="form-control mt-6">
                            <button type='submit' className="btn btn-primary text-black">{t('send_message')}</button>
                        </div>
                    </form>
                </div>
            </div>
            
        </div>

    </div>
    );
}

export default Contact;