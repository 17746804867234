import React , {useState,useEffect} from 'react'; 
import LogRight from './../Shared/Tracking/LogRight';
import CreateLog from './../Shared/Tracking/CreateLog';
import './Track.css';
import axios from 'axios';
import {useTranslation} from "react-i18next"; 
import { useParams,Link} from 'react-router-dom';
const Tracking = () =>{
    const {t} = useTranslation(); 
    const {tracking_id} = useParams();
    const [parcelEvent, setParcelEvent] = useState(false);
    const [parcelCreateEvent, setParcelCreateEvent] = useState(false);
 
     useEffect(() => {
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/v10/parcel/tracking/${tracking_id}`)
            .then((res)=>{
                setParcelCreateEvent(res.data.data['parcel']);
                setParcelEvent(res.data.data['events']);
            })
            .catch((error)=>{
                setParcelCreateEvent(false);
                setParcelEvent(false);
                console.log(error);
            });
     }, [tracking_id]);
 
 
     //Parcel tracking 
     const [trackId, seTrackId] = useState(tracking_id);
     const TrackHanlder = (e)=>{
       e.preventDefault();  
    }
    return (
            <>
               
                <div name='coverage' className='container px-4 mx-auto py-10' style={{ marginTop:"100px" }} data-aos="fade-up-right" data-aos-duration="1500" data-aos-delay="1100">
                    <div className='text-center mb-10'>
                       
                            <div className=' items-center'> 
                                <input type="tel" className="px-3 py-2.5 text-base font-normal bg-white bg-clip-padding border border-solid border-orange-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none rounded-tr rounded-br w-[70%] " value={trackId} name='track_id' onChange={(e)=>seTrackId(e.target.value)} />
                                <a href={`/track/${trackId}`}  className='btn btn-primary rounded shadow-xl border-orange-300 mx-3 text-black'>{t('track_now')}</a>
                            </div> 
                         
                    </div>
                    
                    {/* Contact title Start */}
                <div className="text-4xl font-semibold text-primary flex justify-center mb-10">
                        <div>
                            <h2 className="">{t('parcel_tracking')}</h2>
                            <div className="border border-t-2 border-t-primary w-50  "></div>
                        </div> 
                    </div> 
                    {/* Contact title end  */}

                    <div className="overflow-x-auto">

                        <section className="cd-timeline js-cd-timeline">
                        {parcelCreateEvent && parcelCreateEvent?  <div className='mb-10 text-center'>
                                <b style={{ textTransform: 'uppercase' }}>{t('parcel_tracking_id')}:</b> <span className='text-orange-500' >#{parcelCreateEvent.tracking_id} </span>
                            </div>:""
                        }
                        {parcelCreateEvent && parcelCreateEvent?
                                <>
                                    {
                                        parcelEvent && parcelEvent.map((event,index)=>{
                                            return <LogRight key={index} event={event} />; 
                                        })
                                    }
                                    {parcelCreateEvent && <CreateLog event={parcelCreateEvent} />  } 
                                </>:<h2 className='notfound'>{t('parcel_not_found')}</h2>
                            } 
                        </section>
                    </div>
                </div>
            </>

          );
}

export default Tracking;