import {useTranslation} from "react-i18next"; 
import ScrollToTop from "../ScrollToTop";
const PrivacyPolicy  = ()=>{
    const {t} = useTranslation(); 
  
    return (
        <>
        <ScrollToTop/>
          
            <div name='privacypolicy' className='container px-4 mx-auto py-10' style={{ marginTop:"100px" }} data-aos="fade-up-right" data-aos-duration="1500" data-aos-delay="1100">
                        {/* Contact title Start */}
                        <div className="text-4xl font-semibold text-primary flex justify-center mb-10">
                        <div>
                            <h2 className="">{t('privacy_policy')}   </h2>
                            <div className="border border-t-2 border-t-primary w-16 mt-3"></div>
                        </div>
                </div>
                {/* Contact title end  */}

                <div >
                    <h3 style={{ fontWeight:"bold",fontSize:"20px" }}>1. {t('introduction')}</h3>
                    <p>{t('introduction')}</p>
                </div>

                <div className='mt-5'>
                    <h3 style={{ fontWeight:"bold",fontSize:"20px" }}>2. {t('information_from_merchants')}</h3>
                    <p>{t('information_from_merchants_content')}</p>
                    <ul className="list-disc p-6">
                        <li>list </li>
                        <li>list </li>
                        <li>list </li>
                    </ul>
                </div>

                <div className='mt-5'>
                    <h3 style={{ fontWeight:"bold",fontSize:"20px" }}>3. {t('information_from_our_merchants_customers')}</h3>
                    <p>{t('information_from_our_merchants_customers_content')}</p>
                </div>

                <div className='mt-5'>
                    <h3 style={{ fontWeight:"bold",fontSize:"20px" }}>4. {t('information_from_partners')}</h3>
                    <p>{t('information_from_partners_content')}</p>
                </div>
                <div className='mt-5'>
                    <h3 style={{ fontWeight:"bold",fontSize:"20px" }}>5. {t('efr')}</h3>
                    <p>{t('efr_content')}</p>
                </div>


                <div className='mt-5'>
                    <h3 style={{ fontWeight:"bold",fontSize:"20px" }}>6. {t('information_from_cookies')}</h3>
                    <p>{t('information_from_cookies_content')}</p>
                </div>

                <div className='mt-5'>
                    <h3 style={{ fontWeight:"bold",fontSize:"20px" }}>7. {t('persional_information')}</h3>
                    <p>{t('persional_information_content')}</p>
                    <ul className="list-disc p-6">
                        <li>list </li>
                        <li>list </li>
                        <li>list </li>
                    </ul>
                </div>

                <div className='mt-5'>
                    <h3 style={{ fontWeight:"bold",fontSize:"20px" }}>8. {t('persional_information_terminate')}</h3>
                    <p>{t('persional_information_terminate_content')}</p>
                    <ul className="list-disc p-6">
                        <li>list </li>
                        <li>list </li>
                        <li>list </li>
                    </ul>
                </div>

                <div className='mt-5'>
                    <h3 style={{ fontWeight:"bold",fontSize:"20px" }}>9. {t('do_not_persional_information')}</h3>
                    <p>{t('do_not_persional_information_content')}</p>
                    <ul className="list-disc p-6">
                        <li>list </li>
                        <li>list </li>
                        <li>list </li>
                    </ul>
                </div>

            </div>
        </>
    );
}

export default PrivacyPolicy;