import heroImg from '../../assets/images/delivery-rx-tranfarency.png';
import React , {useState} from 'react';
import { Link, useLocation,useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next"; 
import { createBrowserHistory } from 'history';

export default function TopBanner() {
  
    const {t} = useTranslation(); 
    const [mobile, setMobile] = useState({phone:''});
    const location = useLocation (); 
    const Navigate = useNavigate (); 
    const JoinHandler=(e)=>{
        e.preventDefault();
        const history = createBrowserHistory(); 
        history.push({pathname:`${process.env.REACT_APP_BASE_URL}/merchant/sign-up?phone=${mobile.phone}`}); 
    } 
    //Parcel tracking 
    const [trackId, seTrackId] = useState(' ');
    const TrackHanlder = (e)=>{
        e.preventDefault();
       Navigate(`track/${trackId}`); 
    }
   
  return (
        <div name="home" style={{ backgroundImage: `url(/animated-bg.svg)` }} id="banner-clip"  > 
          <div className='h-screen bg-cover bg-primary opacity-90' id="top-banner" style={{ height:'1200px' }} >
              <div className='topbanner container mx-auto px-4'>
                  <div className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-10 justify-between items-center pt-0 h-screen'>
                      <div className='font-semibol' data-aos="fade-right" data-aos-duration="1000">
                          <div className='text-2xl md:text-5xl uppercase text-white font-bold leading-tight flex flex-wrap gap-4'>
                              <h1 className='  text-center md:text-left w-full text-black'>{t('we_provide')}</h1>
                              <h2 className='text-black pt-2 pb-2 w-[430px] bg-white   text-center -skew-x-12 rounded-lg'>{t('hassle_free')}  </h2>
                              <h2 className='text-black pt-2 pb-2 w-[430px] bg-white   text-center -skew-x-12 rounded-lg'>{t('fastest_delivery')  }</h2>
                          </div>

                          <p className='text-white mt-2 hidden md:inline-block text-black'>{t('top_banner_content')}</p>
                                <div className='mt-4 p-4 rounded-lg left-banner-input-box pb-7 min-h-xs'>
                                    <h3 className='text-3xl font-semibold mb-2 text-black mt-0'>{t('join_as')} {t('merchant')} </h3>
                                    <form onSubmit={JoinHandler}>
                                        <div className='flex items-center'>
                                            <span className='bg-white text-netral rounded-tl rounded-bl py-2.5 px-2 border border-solid border-orange-300 border-r-0 text-base font-normal'>+880</span>
                                            <input type="tel" className="w-[265px] input-1 px-3 py-2.5 text-base font-normal bg-white bg-clip-padding border border-solid border-orange-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none rounded-tr rounded-br w-full max-w-xs" name='mobile' onChange={(e)=>setMobile({...mobile,phone:e.target.value})} />
                                            <button type='submit' className='btn btn-primary text-black rounded shadow-xl border-orange-300 mx-3'>{t('join_now')}</button>
                                        </div>
                                        
                                    </form>
                                </div>
                                <div className='mt-4  or-box' style={{ borderTop: '1px solid black',width:"80%" }}>
                                    <span className='or '>{t('or')}</span>
                                </div>
                                <div className='mt-4 p-4 rounded-lg left-banner-input-box pb-7 min-h-xs'>
                                    <h3 className=' mt-5 text-3xl font-semibold mb-2 text-black mt-0'>{t('enter_parcel_tracking_id')}</h3>
                                    <form onSubmit={TrackHanlder}>
                                        <div className='flex items-center'>
                                            <input type="tel" className="input-2 px-3 py-2.5 text-base font-normal bg-white bg-clip-padding border border-solid border-orange-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none rounded-tr rounded-br w-full max-w-xs" name='track_id' onChange={(e)=>seTrackId(e.target.value)} />
                                            <button type='submit' className='btn btn-primary text-black rounded shadow-xl border-orange-300 mx-3' style={{ paddingRight:"10px",paddingLeft:"10px" }}>{t('track_now')}</button>
                                        </div>
                                        
                                    </form>
                                </div>
                  
                      </div>

                      <div className='h-img-box bg-base-100 rounded-lg flex justify-center -mt-[250px] md:mt-0' id="baner-right" style={{ background:'none' }}>
                          <img  src={heroImg} className="rounded-3xl" alt='' data-aos="fade-left" data-aos-duration="1000"></img>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  )
}
