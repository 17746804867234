import React, {useState, useEffect} from  'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'; 
import axios from 'axios';  
import {useTranslation} from "react-i18next"; 
const Pricing = ()=>{
     const {t} = useTranslation();
     const [charges, setCharges] = useState(false);
     useEffect(() => {
         axios.get(`${process.env.REACT_APP_BASE_URL}/api/v10/delivery-charges`)
         .then((res)=>{
            setCharges(res.data.data);
         })
         .catch((error)=>{
            console.log(error);
         });
     }, []);
 
    return (
            <section name="pricing" className='container px-4 mx-auto py-10' data-aos="fade-up-right" data-aos-duration="1500" data-aos-delay="800">
            {/* Pricing Start */}
            <div className="text-4xl font-semibold text-primary flex justify-center my-10">
                <div>
                    <h2 className="">{t('rx_courier_pricing')}</h2>
                    <div className="border border-t-2 border-t-primary w-16 mt-3"></div>
                </div>
            </div>
            {/* Pricing end  */} 
            <div> 
                <Tabs>
                    <div className='flex justify-center'>
                        <TabList>
                           <Tab><h4 className='text-xl'>{t('same_day')}</h4></Tab>
                            <Tab><h4 className='text-xl'>{t('next_day')}</h4></Tab>
                            <Tab><h4 className='text-xl'>{t('sub_city')}</h4></Tab>
                            <Tab><h4 className='text-xl'>{t('outside_city')}</h4></Tab>
                        </TabList>
                    </div>
                     
                    <TabPanel>    
                        {charges && 
                            <div className="w-full mx-auto overflow-auto   rounded-md mt-10 text-gray-600 body-font">
                                    <div className='c-border-none grid grid-cols-1 sm:grid-cols-2  md:grid-cols-6 lg:grid-cols-8   w-full' >
                                        {charges.map((charge,index)=> 
                                            <div className="px-4 py-3 border text-center" key={index}>
                                                <p className='font-semibold'>{t('up_to')} {charge.weight} ( {charge.category && charge.category.title} )</p>
                                                <p className='text-3xl font-bold mt-3'>৳ {charge.same_day} </p>
                                            </div>  
                                        )}
                                    </div>
                            
                            </div>
                        }
                    </TabPanel>


                    <TabPanel>    
                        <div className="w-full mx-auto overflow-auto  rounded-md mt-10 text-gray-600 body-font">
                            {charges && 
                                <div className='c-border-none grid grid-cols-1 sm:grid-cols-2  md:grid-cols-6 lg:grid-cols-8  w-full' >
                                    {charges.map((charge,index)=>
                                            <div className="px-4 py-3 border text-center" key={index}>
                                                <p className='font-semibold'>{t('up_to')} {charge.weight} ( {charge.category && charge.category.title} )</p>
                                                <p className='text-3xl font-bold mt-3'>৳ {charge.next_day} </p>
                                            </div> 
                                        )}
                                </div>
                            }
                        </div>
                    </TabPanel>

                    
                    <TabPanel>    
                        <div className="w-full mx-auto overflow-auto  rounded-md mt-10 text-gray-600 body-font">
                            {charges && 
                                <div className='c-border-none grid grid-cols-1 sm:grid-cols-2  md:grid-cols-6 lg:grid-cols-8   w-full' >
                                    {charges.map((charge,index)=>
                                        <div className="px-4 py-3 border text-center" key={index}>
                                            <p className='font-semibold'>{t('up_to')} {charge.weight} ( {charge.category && charge.category.title} )</p>
                                            <p className='text-3xl font-bold mt-3'>৳ {charge.sub_city} </p>
                                        </div> 
                                    )}
                                </div>
                            }
                        </div>
                    </TabPanel>

                    <TabPanel>    
                        <div className="w-full mx-auto overflow-auto rounded-md mt-10 text-gray-600 body-font">
                            {charges && 
                                    <div className='c-border-none grid  grid-cols-1 sm:grid-cols-2  md:grid-cols-6 lg:grid-cols-8    w-full' >
                                        {charges.map((charge,index)=>
                                                <div className="px-4 py-3 border text-center" key={index}>
                                                    <p className='font-semibold'>{t('up_to')} {charge.weight} ( {charge.category && charge.category.title} )</p>
                                                    <p className='text-3xl font-bold mt-3'>৳ {charge.outside_city} </p>
                                                </div> 
                                            )}
                                    </div>
                            }
                        </div>
                    </TabPanel>

                </Tabs>

            </div>
 

        </section>
    );
}

export default Pricing;